<template>
  <Dialog
    :title="title"
    :closable="true"
    :buttons="buttons"
    @close="close">
    <FormField>
      <TextInputField
        v-model="editedName"
        :placeholder="placeholder">
      </TextInputField>

      <HelpText
        v-if="missingName"
        type="danger"
        :text="$t('document.missingName')">
      </HelpText>
    </FormField>
  </Dialog>
</template>

<script>
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

export default {
  components: {
    Dialog
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      editedName: this.name,
      missingName: false
    }
  },

  computed: {
    buttons () {
      return [{
        title: this.$i18n.t('general.cancel'),
        click: this.close
      }, {
        title: this.$i18n.t('general.save'),
        type: 'primary',
        click: this.submit
      }]
    }
  },

  methods: {
    async submit () {
      if (this.validate()) {
        this.$emit('submit', this.editedName)
      }
    },

    close () {
      this.$emit('close')
    },

    validate () {
      this.missingName = this.editedName.length === 0
      return this.missingName === false
    }
  }
}
</script>
