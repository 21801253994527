<template>
  <TableRow>
    <TableCell :class="classList" class="is-narrow" @click="preview">
      <Icon
        :name="icon"
        :class="[iconColor]"
        class="is-medium">
      </Icon>
    </TableCell>

    <TableCell :class="classList" @click="preview">
      <div v-if="isEditing" class="field has-addons">
        <div class="control is-expanded">
          <TextInputField v-model="name" size="small" />
        </div>

        <div class="control">
          <Button
            class="has-text-success"
            icon="icon-save"
            size="small"
            :tooltip="$t('general.save')"
            :disabled="!hasValidName"
            @click.stop="save">
          </Button>
        </div>

        <div class="control">
          <Button
            class="has-text-danger"
            icon="icon-x"
            :tooltip="$t('general.cancel')"
            size="small"
            @click.stop="cancel">
          </Button>
        </div>
      </div>

      <a v-else>{{ document.name }}</a>
    </TableCell>

    <TableCell :class="classList" class="is-hidden-mobile created" @click="preview">
      {{ createdAt }}<br>
      <small class="created-by">av {{ createdBy }}</small>
    </TableCell>

    <TableCell :class="classList" class="is-hidden-mobile" @click="preview">
      {{ document.size }}
    </TableCell>

    <TableCell class="has-text-right">
      <InlineLoader v-if="isUploading" size="small" />

      <DocumentsMoreButton v-else>
        <DropdownItem @click="download">{{ $t('document.download') }}</DropdownItem>
        <DropdownItem @click="rename">{{ $t('document.rename') }}</DropdownItem>
        <DropdownItem @click="move">{{ $t('document.move') }}</DropdownItem>
        <DropdownDivider />
        <DropdownItem @click="remove">{{ $t('document.remove') }}</DropdownItem>
      </DocumentsMoreButton>
    </TableCell>
  </TableRow>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentsMoreButton from '../DocumentsMoreButton.vue'
import { DOCUMENT_STATUS_UPLOADING } from '../../models/documentModel'
import icons from '../../documentIcons.js'

export default {
  components: {
    DocumentsMoreButton
  },

  props: {
    document: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      name: ''
    }
  },

  computed: {
    ...mapGetters({
      members: 'huntarea/members/members'
    }),

    isUploading () {
      return this.document.status === DOCUMENT_STATUS_UPLOADING
    },

    isEditing () {
      return this.document.editing
    },

    classList () {
      if (this.isUploading) {
        return 'is-dimmed is-disabled'
      }

      return this.isEditing ? '' : 'is-clickable'
    },

    icon () {
      return icons[this.document.type] || 'icon-file'
    },

    iconColor () {
      const types = {
        pdf: 'red',
        ppt: 'red',
        pptx: 'red',
        xls: 'green',
        xlsx: 'green',
        document: 'blue',
        docx: 'blue',
        mp3: 'blue'
      }

      const colors = {
        red: 'has-text-danger',
        green: 'has-text-success',
        blue: 'has-text-info'
      }

      return colors[types[this.document.type]] || 'has-text-grey'
    },

    createdBy () {
      const members = this.members || []
      const i = members.findIndex(member => member.id === this.document.createdBy)
      return i !== -1 ? members[i].getFullName() : 'Okänd'
    },

    createdAt () {
      return this.$dayjs(this.document.createdAt).format('YYYY-MM-DD HH:mm')
    },

    hasValidName () {
      return this.name.length > 0
    }
  },

  methods: {
    preview (event) {
      if (!this.isEditing) {
        this.$emit('preview', this.document)
      }
    },

    download () {
      this.$emit('download', this.document)
    },

    rename () {
      this.$store.dispatch('huntarea/documents/startRename', this.document)
      this.name = this.document.name
    },

    async save () {
      const currentName = this.document.name // Backup name in case of error
      this.document.name = this.name // Update UI directly

      this.cancel()

      try {
        await this.$store.dispatch('huntarea/documents/updateDocument', this.document)
      } catch (error) {
        this.document.name = currentName

        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }
    },

    cancel () {
      this.$store.commit('huntarea/documents/cancelRename')
    },

    move () {
      this.$emit('move', this.document)
    },

    remove () {
      this.$emit('remove', this.document)
    }
  }
}
</script>

<style scoped>
a {
  color: black;
}

.created {
  line-height: 1rem;
}

.created-by {
  font-size: 0.8rem;
  color: grey;
}

.document-icon {
  width: 1.5rem;
  height: 1.5rem;
}
</style>
