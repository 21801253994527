<template>
  <DocumentsGridViewItem
    icon="icon-folder"
    :name="folder.name"
    @click="openFolder">
    <DropdownItem @click="rename">{{ $t('document.rename') }}</DropdownItem>
    <DropdownItem @click="move">{{ $t('document.move') }}</DropdownItem>
    <DropdownDivider />
    <DropdownItem @click="remove">{{ $t('document.remove') }}</DropdownItem>
  </DocumentsGridViewItem>
</template>

<script>
import DocumentsGridViewItem from './DocumentsGridViewItem.vue'

export default {
  components: {
    DocumentsGridViewItem
  },

  props: {
    folder: {
      type: Object,
      required: true
    }
  },

  methods: {
    openFolder () {
      this.$store.dispatch('huntarea/documents/setCurrentFolder', this.folder)
    },

    rename () {
      this.$emit('rename', this.folder)
    },

    move () {
      this.$emit('move', this.folder)
    },

    remove () {
      this.$emit('remove', this.folder)
    }
  }
}
</script>
