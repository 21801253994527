<template>
  <div>
    <EmptyState
      v-if="isSearching"
      icon="icon-search"
      :tagline="$t('document.noDocumentsMatchedSearch', { search: searchText })"
      :title="$t('document.noDocumentsMatchedSearchTitle')">
    </EmptyState>

    <EmptyState
      v-else
      icon="icon-file"
      :tagline="$t('document.noDocumentsUploaded')"
      :title="$t('document.noDocumentsTitle')">
      <DocumentsUploadButton />
    </EmptyState>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentsUploadButton from './DocumentsUploadButton.vue'

export default {
  components: {
    DocumentsUploadButton
  },

  computed: {
    ...mapGetters({
      folders: 'huntarea/documents/getFolders',
      documents: 'huntarea/documents/getDocuments',
      searchText: 'huntarea/documents/getSearch'
    }),

    isSearching () {
      return this.searchText.length > 0
    }
  }
}
</script>
