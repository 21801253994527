<template>
  <GridViewItem>
    <div
      class="documents-grid-view-item"
      :class="{ 'is-disabled': loading }"
      @click="$emit('click', $event)">
      <Icon :name="icon" :class="iconColor" class="is-medium" />
      <p class="name" :class="{ 'is-dimmed': loading }">{{ name }}</p>
    </div>

    <InlineLoader v-if="loading" size="small" class="more" />

    <DocumentsMoreButton v-else class="more">
      <slot />
    </DocumentsMoreButton>
  </GridViewItem>
</template>

<script>
import DocumentsMoreButton from '../DocumentsMoreButton.vue'

export default {
  components: {
    DocumentsMoreButton
  },

  props: {
    icon: {
      type: String,
      default: ''
    },

    iconColor: {
      type: String,
      default: ''
    },

    loading: {
      type: Boolean,
      default: false
    },

    name: {
      type: String,
      default: ''
    }
  },

  computed: {
    classes () {
      return this.loading ? 'is-dimmed is-disabled' : ''
    }
  }
}
</script>

<style scoped>
.documents-grid-view-item {
  position: relative;
  background-color: white;
  border: 1px solid white;
  border-radius: 4px;
  height: 128px;
  min-width: 128px;
  max-height: 128px;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  overflow-y: hidden;
}

.documents-grid-view-item:hover {
  border: 1px solid #eb914e;
  color: #eb914e;
}

.documents-grid-view-item .name {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.more {
  position: absolute;
  top: 5px;
  right: 15px;
}
</style>
