<template>
  <DocumentsTable>
    <TableContent v-if="isLoading">
      <DocumentsTablePlaceholderRow
        v-for="i in 10"
        :key="i"
        :columns="4">
      </documentstableplaceholderrow>
    </TableContent>

    <TableContent v-else>
      <DocumentsTableFolderRow
        v-for="folder in folders"
        :key="folder.id"
        :folder="folder"
        @remove="removeFolder"
        @move="moveFolder"
        @rename="renameFolder">
      </DocumentsTableFolderRow>

      <DocumentsTableFileRow
        v-for="document in documents"
        :key="document.id"
        :document="document"
        @move="moveDocument"
        @preview="previewDocument"
        @remove="removeDocument"
        @download="downloadDocument">
      </DocumentsTableFileRow>

      <TableRow v-if="isEmpty">
        <TableCell colspan="5">
          <DocumentsEmptyState />
        </TableCell>
      </TableRow>
    </TableContent>
  </DocumentsTable>
</template>

<script>
import { mapGetters } from 'vuex'

import DocumentsTable from './DocumentsTable.vue'
import DocumentsTablePlaceholderRow from './DocumentsTablePlaceholderRow.vue'
import DocumentsTableFolderRow from './DocumentsTableFolderRow.vue'
import DocumentsTableFileRow from './DocumentsTableFileRow.vue'
import DocumentsEmptyState from '../DocumentsEmptyState.vue'

export default {
  components: {
    DocumentsTable,
    DocumentsTablePlaceholderRow,
    DocumentsTableFolderRow,
    DocumentsTableFileRow,
    DocumentsEmptyState
  },

  computed: {
    ...mapGetters({
      folders: 'huntarea/documents/getFolders',
      documents: 'huntarea/documents/getDocuments'
    }),

    isLoading () {
      return this.folders === null || this.documents === null
    },

    isEmpty () {
      return this.folders.length === 0 && this.documents.length === 0
    }
  },

  methods: {
    previewDocument (document) {
      this.$emit('preview-document', document)
    },

    downloadDocument (document) {
      this.$emit('download-document', document)
    },

    moveDocument (document) {
      this.$emit('move-document', document)
    },

    removeDocument (document) {
      this.$emit('remove-document', document)
    },

    removeFolder (folder) {
      this.$emit('remove-folder', folder)
    },

    renameFolder (folder) {
      this.$emit('rename-folder', folder)
    },

    moveFolder (folder) {
      this.$emit('move-folder', folder)
    }
  }
}
</script>
