<template>
  <Dialog
    :title="$t('document.createFolder')"
    :closable="true"
    :close-enabled="!isSubmitting"
    :buttons="buttons"
    @close="close">
    <FormField>
      <TextInputField
        v-model="folderName"
        :placeholder="$t('document.nameOnFolder')"
        :disabled="isSubmitting">
      </TextInputField>

      <HelpText
        v-if="missingName"
        type="danger"
        :text="$t('document.missingFolderName')">
      </HelpText>
    </FormField>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

const STATUS_DEFAULT = ''
const STATUS_MISSING_NAME = 'missing-name'
const STATUS_SUBMITTING = 'submitting'

export default {
  components: {
    Dialog
  },

  data () {
    return {
      folderName: '',
      status: STATUS_DEFAULT
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected'
    }),

    buttons () {
      return [{
        title: this.$i18n.t('general.cancel'),
        disabled: this.isSubmitting,
        click: this.close
      }, {
        title: this.$i18n.t('general.create'),
        type: 'primary',
        loading: this.isSubmitting,
        click: this.submit
      }]
    },

    isSubmitting () {
      return this.status === STATUS_SUBMITTING
    },

    missingName () {
      return this.status === STATUS_MISSING_NAME
    }
  },

  methods: {
    async submit () {
      this.status = STATUS_DEFAULT

      if (this.validate()) {
        this.status = STATUS_SUBMITTING

        try {
          await this.createFolder()
          this.close()
        } catch (error) {
          this.status = STATUS_DEFAULT

          this.$notification.danger(this.$t('document.createFolderFailedErrorMesssage'))
          console.error(error)
        }
      }
    },

    createFolder () {
      return this.$store.dispatch('huntarea/documents/createFolder', this.folderName)
    },

    close () {
      this.$emit('close')
    },

    validate () {
      if (this.folderName.length === 0) {
        this.status = STATUS_MISSING_NAME
        return false
      }
      return true
    }
  }
}
</script>
