<template>
  <div class="breadcrumb">
    <ul>
      <li v-for="folder in path" :key="folder.id" @click="selectFolder(folder)">
        <a :class="{ 'current': isCurrentFolder(folder) }">
          <Icon name="icon-folder" /> {{ name(folder) }}
        </a>

        <Icon v-if="!isCurrentFolder(folder)" name="icon-chevron-right" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    currentFolder: {
      type: Object,
      required: true
    }
  },

  computed: {
    path () {
      let folder = this.currentFolder
      let path = []

      while (folder !== null) {
        path.splice(0, 0, folder)
        folder = folder.parent
      }

      return path
    }
  },

  methods: {
    name (folder) {
      return folder.name.length > 0 ? folder.name : this.$i18n.t('document.title')
    },

    selectFolder (folder) {
      this.$emit('select-folder', folder)
    },

    isCurrentFolder (folder) {
      return folder.id === this.currentFolder.id
    }
  }
}
</script>

<style scoped>
.breadcrumb {
  white-space: nowrap;
  background-color: white;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  overflow-x: auto;
  border-radius: 4px;
}

.breadcrumb ul {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.breadcrumb li {
  align-items: center;
  display: flex;
}

.breadcrumb a {
  align-items: center;
  color: grey;
  display: flex;
  justify-content: center;
  padding: 0;
}

.breadcrumb a.current {
  color: black;
}
</style>
