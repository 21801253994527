<template>
  <DocumentsGridViewItem
    :icon="icon"
    :icon-color="iconColor"
    :name="document.name"
    :loading="isUploading"
    @click="preview">
    <DropdownItem @click="download">{{ $t('document.download') }}</DropdownItem>
    <DropdownItem @click="rename">{{ $t('document.rename') }}</DropdownItem>
    <DropdownItem @click="move">{{ $t('document.move') }}</DropdownItem>
    <DropdownDivider />
    <DropdownItem @click="remove">{{ $t('document.remove') }}</DropdownItem>
  </DocumentsGridViewItem>
</template>

<script>
import DocumentsGridViewItem from './DocumentsGridViewItem.vue'
import { DOCUMENT_STATUS_UPLOADING } from '../../models/documentModel'
import icons from '../../documentIcons.js'

export default {
  components: {
    DocumentsGridViewItem
  },

  props: {
    document: {
      type: Object,
      required: true
    }
  },

  computed: {
    icon () {
      return icons[this.document.type] || 'icon-file'
    },

    iconColor () {
      const types = {
        pdf: 'red',
        ppt: 'red',
        pptx: 'red',
        xls: 'green',
        xlsx: 'green',
        document: 'blue',
        docx: 'blue',
        mp3: 'blue'
      }

      const classes = {
        red: 'has-text-danger',
        green: 'has-text-success',
        blue: 'has-text-info'
      }

      return classes[types[this.document.type]] || 'has-text-grey'
    },

    isUploading () {
      return this.document.status === DOCUMENT_STATUS_UPLOADING
    }
  },

  methods: {
    preview () {
      this.$emit('preview', this.document)
    },

    download () {
      this.$emit('download', this.document)
    },

    rename () {
      this.$emit('rename', this.document)
    },

    move () {
      this.$emit('move', this.document)
    },

    remove () {
      this.$emit('remove', this.document)
    }
  }
}
</script>
