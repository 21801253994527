export default {
  jpg: 'icon-image',
  JPG: 'icon-image',
  jpeg: 'icon-image',
  JPEG: 'icon-image',
  png: 'icon-image',
  PNG: 'icon-image',
  gif: 'icon-image',
  GIF: 'icon-image',

  mp3: 'icon-play',

  zip: 'icon-zip',
  rar: 'icon-zip',

  pdf: 'icon-pdf',
  txt: 'icon-file',
  doc: 'icon-file',
  docx: 'icon-file',
  xls: 'icon-excel',
  xlsx: 'icon-excel',
  ppt: 'icon-powerpoint',
  pptx: 'icon-powerpoint'
}
