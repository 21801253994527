<template>
  <Dialog
    :title="title"
    :closable="true"
    :close-enabled="!submitting"
    :buttons="buttons"
    @close="close">
    <Paragraph :text="helpText" />

    <DocumentsBreadcrumbs
      :current-folder="currentFolder"
      :class="{ 'is-disabled': submitting }"
      @select-folder="selectFolder">
    </DocumentsBreadcrumbs>

    <div class="folder-table" :class="{ 'is-disabled': submitting }">
      <Table hoverable>
        <TableContent v-if="isLoading">
          <DocumentsTablePlaceholderRow v-for="i in 6" :key="i" :columns="1" />
        </TableContent>

        <TableContent v-else>
          <TableRow
            v-for="folder in folders"
            :key="folder.id"
            @click="selectFolder(folder)">
            <TableCell class="is-narrow">
              <Icon name="icon-folder" class="is-medium" />
            </TableCell>

            <TableCell>
              {{ folder.name }}
            </TableCell>
          </TableRow>

          <TableRow v-if="folders.length === 0">
            <TableCell colspan="2" class="has-text-grey has-text-centered">
              {{ $t('document.noMoreFolders') }}
            </TableCell>
          </TableRow>
        </TableContent>
      </Table>
    </div>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import Dialog from '@/components/ui/dialog2/Dialog2.vue'
import DocumentsBreadcrumbs from '../DocumentsBreadcrumbs.vue'
import DocumentsTablePlaceholderRow from '../list/DocumentsTablePlaceholderRow.vue'

export default {
  components: {
    Dialog,
    DocumentsBreadcrumbs,
    DocumentsTablePlaceholderRow
  },

  props: {
    object: {
      type: Object,
      default: () => {}
    },

    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      submitting: false,
      currentFolder: null
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected'
    }),

    isLoading () {
      return this.currentFolder.folders === null
    },

    folders () {
      // It's not allowed to move a folder to itself, so remove it from the list
      if (this.type === 'folder') {
        return this.currentFolder.folders.filter(folder => folder.id !== this.object.id)
      }
      return this.currentFolder.folders
    },

    buttons () {
      return [{
        title: this.$t('general.cancel'),
        disabled: this.submitting,
        click: this.close
      }, {
        title: this.$i18n.t('general.move'),
        type: 'primary',
        loading: this.submitting,
        click: this.submit
      }]
    },

    title () {
      const types = {
        document: 'document.moveDocumentTitle',
        folder: 'document.moveFolderTitle'
      }

      return this.$i18n.t(types[this.type])
    },

    helpText () {
      const types = {
        document: 'document.moveDocumentHelpText',
        folder: 'document.moveFolderHelpText'
      }

      return this.$i18n.t(types[this.type], { name: this.object.name })
    }
  },

  created () {
    this.currentFolder = this.$store.getters['huntarea/documents/getCurrentFolder']
  },

  methods: {
    selectFolder (folder) {
      this.currentFolder = folder
      this.$store.dispatch('huntarea/documents/readFolder', folder)
    },

    close () {
      this.$emit('close')
    },

    async submit () {
      this.submitting = true

      try {
        if (this.type === 'document') {
          await this.$store.dispatch('huntarea/documents/moveDocument', {
            document: this.object,
            destinationFolder: this.currentFolder
          })
        } else {
          await this.$store.dispatch('huntarea/documents/moveFolder', {
            folder: this.object,
            destinationFolder: this.currentFolder
          })
        }

        this.close()
      } catch (error) {
        this.$notification.danger(this.$t('general.unknownErrorMessage'))
        console.error(error)
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style scoped>
.folder-table {
  height: 300px;
  max-height: 300px;
  overflow-y: auto;
  border-top: 1px solid lightgrey;
}

.folder-table tr {
  cursor: pointer;
}
</style>
