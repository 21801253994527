<template>
  <Table fullwidth hoverable>
    <TableHeader>
      <TableHeaderCell class="is-narrow" />
      <TableHeaderCell :heading="$t('general.name')" />
      <TableHeaderCell :heading="$t('general.created')" class="is-hidden-mobile" />
      <TableHeaderCell :heading="$t('general.size')" class="is-hidden-mobile" />
      <TableHeaderCell />
    </TableHeader>

    <slot />
  </Table>
</template>

<script>
export default {
}
</script>
