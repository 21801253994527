<template>
  <TableRow>
    <TableCell class="is-narrow" :class="classList" @click="openFolder">
      <Icon name="icon-folder" class="is-medium" />
    </TableCell>

    <TableCell :class="classList" @click="openFolder">
      <div v-if="isEditing" class="field has-addons">
        <div class="control is-expanded">
          <TextInputField v-model="name" size="small" />
        </div>

        <div class="control">
          <Button
            class="has-text-success"
            icon="icon-save"
            size="small"
            :tooltip="$t('general.save')"
            :disabled="!hasValidName"
            @click.stop="save">
          </Button>
        </div>

        <div class="control">
          <Button
            class="has-text-danger"
            icon="icon-x"
            :tooltip="$t('general.cancel')"
            size="small"
            @click.stop="cancel">
          </Button>
        </div>
      </div>

      <a v-else>
        {{ folder.name }}
      </a>
    </TableCell>

    <TableCell class="is-hidden-mobile created" :class="classList" @click="openFolder">
      {{ createdAt }}<br>
      <small class="created-by">av {{ createdBy }}</small>
    </TableCell>

    <TableCell class="is-hidden-mobile" :class="classList" @click="openFolder">
      --
    </TableCell>

    <TableCell class="has-text-right">
      <DocumentsMoreButton>
        <DropdownItem @click="rename">{{ $t('document.rename') }}</DropdownItem>
        <DropdownItem @click="move">{{ $t('document.move') }}</DropdownItem>
        <DropdownDivider />
        <DropdownItem @click="remove">{{ $t('document.remove') }}</DropdownItem>
      </DocumentsMoreButton>
    </TableCell>
  </TableRow>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentsMoreButton from '../DocumentsMoreButton.vue'

export default {
  components: {
    DocumentsMoreButton
  },

  props: {
    folder: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      name: ''
    }
  },

  computed: {
    ...mapGetters({
      members: 'huntarea/members/members'
    }),

    isEditing () {
      return this.folder.editing
    },

    createdAt () {
      return this.$dayjs(this.folder.createdAt).format('YYYY-MM-DD HH:mm')
    },

    createdBy () {
      const members = this.members || []
      const i = members.findIndex(member => member.id === this.folder.createdBy)
      return i !== -1 ? members[i].getFullName() : 'Okänd'
    },

    hasValidName () {
      return this.name.length > 0
    },

    classList () {
      return this.isEditing ? '' : 'is-clickable'
    }
  },

  methods: {
    openFolder () {
      if (!this.isEditing) {
        this.$store.dispatch('huntarea/documents/setCurrentFolder', this.folder)
      }
    },

    rename () {
      this.$store.dispatch('huntarea/documents/startRename', this.folder)
      this.name = this.folder.name
    },

    async save () {
      const currentName = this.folder.name // Backup name in case of error
      this.folder.name = this.name // Update UI directly

      this.cancel()

      try {
        await this.$store.dispatch('huntarea/documents/updateFolder', this.folder)
      } catch (error) {
        this.folder.name = currentName

        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }
    },

    cancel () {
      this.$store.commit('huntarea/documents/cancelRename')
    },

    remove () {
      this.$emit('remove', this.folder)
    },

    move () {
      this.$emit('move', this.folder)
    }
  }
}
</script>

<style scoped>
a {
  color: black;
}

.created {
  line-height: 1rem;
}

.created-by {
  font-size: 0.8rem;
  color: grey;
}
</style>
