<template>
  <Toolbar class="documents-toolbar">
    <ToolbarItemGroup alignment="left">
      <ToolbarItem>
        <DocumentsUploadButton />
      </ToolbarItem>

      <ToolbarItem>
        <Button
          type="secondary"
          :tooltip="$t('document.createFolder')"
          :title="$t('document.createFolder')"
          icon="icon-folder-plus"
          @click="createFolder">
        </Button>
      </ToolbarItem>
    </ToolbarItemGroup>

    <ToolbarItemGroup alignment="right">
      <ToolbarItem class="is-hidden-touch">
        <SearchInputField v-model="search" class="search-field" />
      </ToolbarItem>

      <ToolbarItem>
        <div class="field is-hidden-touch">
          <div class="control">
            <div class="select">
              <select v-model="sortOrder">
                <option disabled value="">{{ $t('general.sort') }}</option>
                <option value="nameAZ">{{ $t('general.nameAZ') }}</option>
                <option value="nameZA">{{ $t('general.nameZA') }}</option>
              </select>
            </div>
          </div>
        </div>
      </ToolbarItem>

      <ToolbarItem>
        <ButtonGroup segmented>
          <Button
            :tooltip="$t('general.list')"
            icon="icon-align-justify"
            @click="setListView">
          </Button>

          <Button
            :tooltip="$t('general.grid')"
            icon="icon-grid"
            @click="setGridView">
          </Button>
        </ButtonGroup>
      </ToolbarItem>
    </ToolbarItemGroup>
  </Toolbar>
</template>

<script>
import DocumentsUploadButton from './DocumentsUploadButton.vue'

import { DOCUMENT_VIEW_GRID, DOCUMENT_VIEW_LIST } from '../store/documentStore'

export default {
  components: {
    DocumentsUploadButton
  },

  computed: {
    search: {
      get () {
        return this.$store.getters['huntarea/documents/getSearch']
      },

      set (searchText) {
        this.$store.commit('huntarea/documents/setSearch', searchText)
      }
    },

    sortOrder: {
      get () {
        return this.$store.getters['huntarea/documents/getSortOrder']
      },

      set (sortOrder) {
        this.$store.commit('huntarea/documents/setSortOrder', sortOrder)
      }
    }
  },

  methods: {
    setListView () {
      this.$store.commit('huntarea/documents/setView', DOCUMENT_VIEW_LIST)
    },

    setGridView () {
      this.$store.commit('huntarea/documents/setView', DOCUMENT_VIEW_GRID)
    },

    createFolder () {
      this.$emit('create-folder')
    }
  }
}
</script>

<style scoped>
.documents-toolbar {
  margin-top: 1.5rem;
}

.search-field {
  max-width: 180px;
}
</style>
