<template>
  <TableRow>
    <TableCell class="is-narrow">
      <div class="placeholder-icon" />
    </TableCell>

    <TableCell v-if="columns >= 1">
      <ContentPlaceholders>
        <ContentPlaceholdersText :lines="1" />
      </ContentPlaceholders>
    </TableCell>

    <TableCell v-if="columns >= 2">
      <ContentPlaceholders>
        <ContentPlaceholdersText :lines="1" />
      </ContentPlaceholders>
    </TableCell>

    <TableCell v-if="columns >= 3" class="is-hidden-mobile">
      <ContentPlaceholders>
        <ContentPlaceholdersText :lines="1" />
      </ContentPlaceholders>
    </TableCell>

    <TableCell v-if="columns >= 4" class="is-hidden-mobile">
      <ContentPlaceholders>
        <ContentPlaceholdersText :lines="1" />
      </ContentPlaceholders>
    </TableCell>

    <TableCell v-if="columns >= 5">
      <ContentPlaceholders>
        <ContentPlaceholdersText :lines="1" />
      </ContentPlaceholders>
    </TableCell>
  </TableRow>
</template>

<script>
export default {
  props: {
    columns: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.placeholder-icon {
  width: 1.75rem;
  height: 1.75rem;
  background-color: rgb(240, 240, 240);
  border-radius: 4px;
}
</style>
