<template>
  <div>
    <InlineLoader v-if="isLoading" dark />

    <GridView v-else>
      <DocumentsGridViewFolder
        v-for="folder in folders"
        :key="folder.id"
        :folder="folder"
        @rename="renameFolder"
        @remove="removeFolder"
        @move="moveFolder">
      </DocumentsGridViewFolder>

      <DocumentsGridViewFile
        v-for="document in documents"
        :key="document.id"
        :document="document"
        @preview="previewDocument"
        @download="downloadDocument"
        @remove="removeDocument"
        @move="moveDocument"
        @rename="renameDocument">
      </DocumentsGridViewFile>

      <DocumentsEmptyState v-if="isEmpty" class="empty-state-container" />
    </GridView>

    <DocumentsRenameDialog
      v-if="selectedDocument !== null"
      :title="$t('document.renameDocumentTitle')"
      :placeholder="$t('document.renameDocumentPlaceholder')"
      :name="selectedDocument.name"
      @submit="updateDocument"
      @close="selectedDocument = null">
    </DocumentsRenameDialog>

    <DocumentsRenameDialog
      v-if="selectedFolder !== null"
      :title="$t('document.renameFolderTitle')"
      :placeholder="$t('document.renameFolderPlaceholder')"
      :name="selectedFolder.name"
      @submit="updateFolder"
      @close="selectedFolder = null">
    </DocumentsRenameDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DocumentsGridViewFolder from './DocumentsGridViewFolder.vue'
import DocumentsGridViewFile from './DocumentsGridViewFile.vue'
import DocumentsEmptyState from '../DocumentsEmptyState.vue'
import DocumentsRenameDialog from '../dialogs/DocumentsRenameDialog.vue'

export default {
  components: {
    DocumentsGridViewFolder,
    DocumentsGridViewFile,
    DocumentsEmptyState,
    DocumentsRenameDialog
  },

  data () {
    return {
      selectedDocument: null,
      selectedFolder: null
    }
  },

  computed: {
    ...mapGetters({
      folders: 'huntarea/documents/getFolders',
      documents: 'huntarea/documents/getDocuments'
    }),

    isLoading () {
      return this.folders === null || this.documents === null
    },

    isEmpty () {
      return this.folders.length === 0 && this.documents.length === 0
    }
  },

  methods: {
    previewDocument (document) {
      this.$emit('preview-document', document)
    },

    downloadDocument (document) {
      this.$emit('download-document', document)
    },

    moveDocument (document) {
      this.$emit('move-document', document)
    },

    renameDocument (document) {
      this.selectedDocument = document
    },

    async updateDocument (name) {
      const document = this.selectedDocument
      const backupName = this.selectedDocument.name

      document.name = name // Update name visible in UI
      this.selectedDocument = null // Close modal and perform operation in background

      try {
        await this.$store.dispatch('huntarea/documents/updateDocument', document)
      } catch (error) {
        document.name = backupName

        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }
    },

    removeDocument (document) {
      this.$emit('remove-document', document)
    },

    renameFolder (folder) {
      this.selectedFolder = folder
    },

    async updateFolder (name) {
      const folder = this.selectedFolder
      const backupName = this.selectedFolder.name

      folder.name = name // Update name visible in UI
      this.selectedFolder = null // Close modal and perform operation in background

      try {
        await this.$store.dispatch('huntarea/documents/updateFolder', folder)
      } catch (error) {
        folder.name = backupName

        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }
    },

    moveFolder (folder) {
      this.$emit('move-folder', folder)
    },

    removeFolder (folder) {
      this.$emit('remove-folder', folder)
    }
  }
}
</script>

<style scoped>
.empty-state-container {
  background-color: white;
  border-radius: 4px;
}
</style>
