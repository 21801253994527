<template>
  <Titlebar>
    <h5 slot="title" class="title is-5 is-marginless">{{ $t('document.title') }}</h5>
  </Titlebar>
</template>

<script>
export default {
}
</script>
