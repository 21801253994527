<template>
  <Wrapper>
    <DocumentsTitlebar @create-folder="createFolderDialogVisible = true" />

    <div class="documents-container">
      <DocumentsBreadcrumbs
        :current-folder="currentFolder"
        @select-folder="selectFolder">
      </DocumentsBreadcrumbs>

      <DocumentsToolbar
        @create-folder="createFolderDialogVisible = true">
      </DocumentsToolbar>

      <DocumentsListView
        v-if="isListView"
        @preview-document="previewDocument"
        @download-document="downloadDocument"
        @move-document="moveDocument"
        @remove-document="removeDocument"
        @remove-folder="removeFolder"
        @move-folder="moveFolder">
      </DocumentsListView>

      <DocumentsGridView
        v-if="isGridView"
        @preview-document="previewDocument"
        @download-document="downloadDocument"
        @move-document="moveDocument"
        @remove-document="removeDocument"
        @remove-folder="removeFolder"
        @move-folder="moveFolder">
      </DocumentsGridView>

      <DocumentsMoveDialog
        v-if="selected !== null"
        :object="selected"
        :type="selectedType"
        @close="selected = null">
      </DocumentsMoveDialog>

      <DocumentsCreateFolderDialog
        v-if="createFolderDialogVisible"
        @close="createFolderDialogVisible = false">
      </DocumentsCreateFolderDialog>
    </div>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex'

import DocumentsTitlebar from './DocumentsTitlebar.vue'
import DocumentsToolbar from './DocumentsToolbar.vue'
import DocumentsBreadcrumbs from './DocumentsBreadcrumbs.vue'

import DocumentsGridView from './grid/DocumentsGridView.vue'
import DocumentsListView from './list/DocumentsListView.vue'

import DocumentsCreateFolderDialog from './dialogs/DocumentsCreateFolderDialog.vue'
import DocumentsMoveDialog from './dialogs/DocumentsMoveDialog.vue'

import { DOCUMENT_VIEW_GRID, DOCUMENT_VIEW_LIST } from '../store/documentStore'

export default {
  components: {
    DocumentsTitlebar,
    DocumentsToolbar,
    DocumentsBreadcrumbs,
    DocumentsGridView,
    DocumentsListView,
    DocumentsCreateFolderDialog,
    DocumentsMoveDialog
  },

  data () {
    return {
      selected: null, // document or folder that is selected to be mvoed
      selectedType: null, // document|folder
      createFolderDialogVisible: false
    }
  },

  computed: {
    ...mapGetters({
      currentFolder: 'huntarea/documents/getCurrentFolder',
      view: 'huntarea/documents/getView'
    }),

    isGridView () {
      return this.view === DOCUMENT_VIEW_GRID
    },

    isListView () {
      return this.view === DOCUMENT_VIEW_LIST
    }
  },

  created () {
    const rootFolder = this.$store.getters['huntarea/documents/getRootFolder']
    this.$store.dispatch('huntarea/documents/setCurrentFolder', rootFolder)

    if (this.$mq.phone) {
      this.$store.commit('huntarea/documents/setView', 'list')
    }
  },

  methods: {
    selectFolder (folder) {
      this.$store.dispatch('huntarea/documents/setCurrentFolder', folder)
    },

    async previewDocument (document) {
      try {
        await this.$store.dispatch('huntarea/documents/previewDocument', document)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.unknownErrorMessage'))
      }
    },

    async downloadDocument (document) {
      try {
        await this.$store.dispatch('huntarea/documents/downloadDocument', document)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.unknownErrorMessage'))
      }
    },

    async moveDocument (document) {
      this.selected = document
      this.selectedType = 'document'
    },

    async removeDocument (document) {
      try {
        const response = await this.$dialog.confirm({
          title: this.$t('general.delete'),
          message: this.$t('general.reallyRemove', { name: document.name }),
          ok: this.$t('general.delete')
        })

        if (response.ok) {
          await this.$store.dispatch('huntarea/documents/deleteDocument', document)
        }
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.unknownErrorMessage'))
      }
    },

    moveFolder (folder) {
      this.selected = folder
      this.selectedType = 'folder'
    },

    async removeFolder (folder) {
      try {
        const response = await this.$dialog.confirm({
          title: this.$t('general.delete'),
          message: this.$t('general.reallyRemove', { name: folder.name }),
          ok: this.$t('general.delete')
        })

        if (response.ok) {
          await this.$store.dispatch('huntarea/documents/deleteFolder', folder)
        }
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.unknownErrorMessage'))
      }
    }
  }
}
</script>

<style lang="scss">
.documents-container {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
</style>
