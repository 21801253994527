<template>
  <div class="file">
    <label class="file-label">
      <input
        class="file-input"
        type="file"
        multiple
        @change="uploadFiles($event.target.files)">

      <span class="file-cta">
        <span class="file-icon is-marginless">
          <Icon name="icon-upload" />
        </span>

        <span class="file-label push-left-xs">
          {{ $t('document.uploadFiles') }}
        </span>
      </span>
    </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected'
    })
  },

  methods: {
    uploadFiles (files) {
      for (let i = 0; i < files.length; i++) {
        this.$store.dispatch('huntarea/documents/createDocument', {
          huntAreaId: this.huntArea.id,
          file: files[i]
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file-cta {
  background: #42555b;
  border-color: transparent;
  color: white;

  &:hover {
    background: #3d4e54 !important;
    border-color: transparent;
    color: white !important;
  }
}
</style>
